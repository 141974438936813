.page-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 43px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
.task-domain-title{
  color: #2a78b2;
  font-size: xx-large;
  margin: 10px;
  margin-top: 40px;
}
.page-section::after {
  content: "";
  background: url('../assets/logo3.png') no-repeat center center;
  background-size: contain;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1; /* Ensure it's behind other content */
}

.instruction-toggle {
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
  background-color: #1a7847;
  width: fit-content;
  padding: 4px;
  font-size: 18px;
  border-radius: 7px;
  cursor: pointer;
  margin-left: 60%;
}

.instruction-menu {
  margin-bottom: 10px;
  background-color: rgb(219, 243, 243);
  padding: 10px;
  color: black;
  font-size: 18px;
  font-family: monospace;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
  .task {
    margin-bottom: 20px;
  }
  .task-question-title{
    text-align: center;
    background-color: #8cc1dc;
    font-size: x-large;
    padding: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); 
    border-radius: 10px;
  }
  .task-question-description{
    /* background-color: beige; */
    color: black;
    font-size: 17px;
  }
  .task-demo-link{
    text-align: right;
    text-decoration: none;
    margin-left: 70%;
    font-style: italic;
  }
  .task-level{
    font-size: 20px;
  }
  
  @media (max-width: 768px) {
    .page-section {
      width: 85%;
      margin: 0 auto;
      padding: 20px;
      margin-top: 43px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); 
    }
    .task-domain-title{
      color: #2a78b2;
      font-size: x-large;
      margin: 10px;
      margin-top: 40px;
    }
    .instruction-toggle {
      
      margin-left: 10%;
    }
    .task-question-title{
      text-align: center;
      background-color: #8fe3e8;
      font-size: 20px;
    }
    .task-demo-link{
      margin-left: 30%;
    }
    .instruction-menu {
      font-weight: 600;
    }
  
    /* .task {
      padding: 10px;
    } */
  }
  