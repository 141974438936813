.contact-us {
  max-width: 80%;
  margin: 0px auto;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-us-p {
  padding: 0px 25px;
  max-width: 70%;
}
.form {
  width: 650px;
  height: 1600px;
}
/* Responsive styles */
@media screen and (max-width: 768px) {
  .form {
    width: 100%;
    height: 1600px;
  }
  .contact-us-p {
    padding: 0px 25px;
    max-width: 650px;
  }
  .contact-us {
    max-width: 100%;
  }
}
