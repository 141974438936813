.certificate-container {
    text-align: center;
    padding: 20px 0;
  }
  
  .certificate-offered h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .certificate-offered {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .certificate-content {
    margin: 20px;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 20px; 
    max-width: 300px;
  }
  
  .course-img img {
    max-width: 100px;
    height: auto;
    margin-bottom: 15px;
    
  }
  
  .course h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .course p {
    font-size: 1rem;
  }
  