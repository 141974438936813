/* About.css */

.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 63px;
  }
  
  .about-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .about-content {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .about-container {
      padding: 10px;
    }
  
    .about-title {
      font-size: 20px;
    }
  }
  