.navbar {
  /* background-color: #29bcf6; */
  background-image: linear-gradient(120deg, rgba(230, 225, 220, 1), #29bcf6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  max-width: 85%;
  margin: 0 auto;
}
.navbar-container a {
  text-decoration: none;
}
.logo-link {
  display: flex;
  align-items: center;
}
.logo {
  width: 8%;
  height: auto;
  margin-right: 20px;
}

.brand-name {
  font-size: 1.8rem;
  color: #399fc7;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.brand-name:hover {
  transform: scale(1.05); /* Scale the brand name on hover */
  color: #399fc7; /* Change color on hover */
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.nav-links.open {
  display: none;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: rgb(255, 102, 0);
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-line {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
  transition: transform 0.3s, opacity 0.3s;
}

.btn-contact {
  background-color: #ffffff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
}

.menu-line.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu-line.open:nth-child(2) {
  opacity: 0;
}

.menu-line.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .navbar-container {
    padding: 5px 0;
    max-width: 90%;
  }
  .logo {
    width: 18%;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: brightness(100%);
    color: white;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #c8e3ef;
    width: 100%;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    margin: 10px 0;
    padding: 10px;
    text-align: center;
  }
  .nav-links li {
    margin: 10px;
    text-align: center;
    padding: 5px;
  }

  .menu-icon {
    display: block;
  }
}
