/* Tasks.css */

.tasks-container {
    padding: 20px;
    margin-top: 40px;
    text-align: center;
  }
  
  .tasks-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .task-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    padding: 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    box-shadow: 3px 4px 5px 0px rgba(41, 188, 246, 0.5);
    border: 1px solid black;
  }
  
  .task-link:hover {
    cursor: pointer;
  color: white;
  background-color: #29bcf6;
  box-shadow: 3px 4px 0px 0px rgba(0, 0, 0, 0.5);
  }
  
  /* Responsive Styles */
  
  @media (max-width: 768px) {
    .tasks-list {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
  
  @media (max-width: 576px) {
    .tasks-list {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
  