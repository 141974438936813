/* TermsAndConditionsPage.css */
.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 63px;
  }
  
  .terms-title {
    
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h2 {
    color: #666;
    font-size: 1.5em;
    margin-top: 30px;
  }
  
  p {
    color: #444;
    line-height: 1.6;
  }
  
  ul {
    color: #444;
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  