/* PageWithBoxes.css */

.boxes-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 90%;
    margin: 0px auto;
    margin-top: 40px;
  }
  
  .box {
    width: 20%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px dotted rgb(17, 162, 224);
  }
  .box:hover{
    background-color: rgb(160, 218, 244);
  }
  
  .box h2 {
    font-size: 44px;
    margin-bottom: 10px;
    margin-top: -1px;
    color: rgb(45, 186, 228);
    filter: drop-shadow(10px 7px 10px rgb(143, 215, 235));
  }
  .box h3{
    text-align: center;
    color: rgb(169, 96, 139);
    filter: drop-shadow(1px 1px 1px rgb(4, 4, 4));
    font-size: 25px;
  }
  
  .box p {
    font-size: 15px;
    color: #0579d2;
    text-align: center;
  }
  
  /* Media Query for Responsive Layout */
  
  @media (max-width: 768px) {
    .box {
      width: 100%;
    }
  }
  